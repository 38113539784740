import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

import { brandIcons } from "assets/BrandIcons";
import MapImageUrl from "assets/map2_540x.png";
import BG2 from "assets/nature_background_2.png";
import data from "data/product_data.json";
import ProductList from "components/features/ProductList";
import HomeImage from "assets/homeImageMedium.jpg";
import MarkvilleEntrance from "assets/markvilleEntrance.jpg";
import MarkvilleInside from "assets/markvilleInside.png";
import StoreFront from "assets/store/storefront.jpg";
import RedsenolPicture from "assets/homepage/redsenol.png";
import { getTranslation } from "translation/translationService";
import { LanguageContext } from "context/LanguageContextProvider";
import TwoImageColumn from "components/features/TwoImageColumn"
import VerticalWithAlternateImageAndText from "components/features/VerticalWithAlternateImageAndText"
import Cnpa from "assets/homepage/cnpa.png";
import classnames from "classnames";

const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg text-blue-400 underline`;

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8 `;
  // const IllustrationContainer = tw.div`flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none`;

  const imageCss = tw`rounded-4xl`;
  const imageCss2 = tw`rounded-4xl`;
  const imageCssRounded = tw`rounded-full `;
  const imageContainerCss = tw`md:flex-col lg:flex-row`;
  const StyledLink = styled.a`
  ${tw`flex relative items-center border-black/10 border-2 rounded-full w-48 h-48 justify-center`}
  aspect-ratio: 1/1;
  &:hover {
    ${tw`bg-green-100`}
  }
`;
const StyledNotLink = styled.div`
${tw`flex relative items-center w-48 h-48 justify-center`}
aspect-ratio: 1/1;
`;
const Heading = tw.h2`font-black text-3xl md:text-5xl leading-snug`;

  const [state, dispatch] = useContext(LanguageContext);

  const language = state.language.code;
  const homeText = getTranslation(language, "homePage");

  return (
    <AnimationRevealPage>
      <Hero
        heading={
          <>
            {homeText.homeHeader1}{" "}
            <HighlightedText>{homeText.homeHeader2}</HighlightedText>
          </>
        }
        description={homeText.homeHeaderDescription}
        imageSrc={HomeImage}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText={homeText.productLink}
        primaryButtonUrl="/products"
      />
      <div style={{display:"flex", flexDirection: "column", paddingTop: "4rem"  }}>
        <Heading
                style={{ width: "100%",  paddingTop: "2rem" , fontSize: "40px", justifyContent: "center", flexWrap: "wrap", display: "flex", gap: "1rem",}}
>
  {homeText.expoloreOur}
        </Heading>
      <div
        style={{ paddingTop: "2rem" ,justifyContent: "center", flexWrap: "wrap", display: "flex", gap: "1rem",}}
      >
        {brandIcons.filter(({name}) => !!name).map(({image, name}) =>  <StyledLink
              key={name}
              href={`/products/?brand=${name}`}
              target="_blank"
            >
              <img
                key={name}
                style={{ transform: "scale(0.5)" }}
                src={image}
                alt="brand image"
              />
            </StyledLink> )}
      </div>
      <div
              style={{ justifyContent: "center", flexWrap: "wrap", display: "flex", gap: "1rem",}}
              >
      {brandIcons.filter(({name}) => !name).map(({image}) => <StyledNotLink
            >
              <img
                key={image}
                style={{ transform: "scale(0.5)" }}
                src={image}
                alt="brand image"
              />
            </StyledNotLink>)}
      </div>
      </div>
      <VerticalWithAlternateImageAndText
        cards={[{
          imageSrc:
            RedsenolPicture,
          subtitle: homeText.redsenol.subtitle,
          title: homeText.redsenol.title,
          url: "https://enophyto.com/retail-locations",
          linkName: homeText.redsenol.description,
        }, {
          imageSrc:
            Cnpa,
          subtitle: homeText.cnpa.subtitle,
          title: homeText.cnpa.title,
          description: homeText.cnpa.description,

        },]} />
     <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
        css={imageContainerCss}
      >
        <img
          style={{ height: "40vw", width: "70vw" }}
          css={imageCss2}
          src={StoreFront}
          alt="Store Outside"
        />
      </div>
      <MainFeature2
        subheading={<Subheading>{homeText.reputedStore}</Subheading>}
        heading={
          <>
            {homeText.where1}{" "}
            <HighlightedText>{homeText.where2}</HighlightedText>
          </>
        }
        description={homeText.daysOfWeek}
        statistics={[
          {
            value: " 5000 Hwy 7, Markham",
            key: "ON L3R 4M9",
          },
          {
            value: "UNIT 2207 - " + homeText.closest,
          },
        ]}
        primaryButtonText={homeText.getDirections}
        primaryButtonUrl="https://goo.gl/maps/Q1hNv5aD6Zc7c9Nu6"
        imageInsideDiv={true}
        imageSrc={MapImageUrl}
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-2 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
        iframeContent={
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2876.401886043731!2d-79.29074508449526!3d43.868224279114536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d677c0a9be87%3A0x521289f6f3c71ec7!2sCF%20Markville!5e0!3m2!1sen!2sca!4v1640912238042!5m2!1sen!2sca"
            width="500"
            height="500"
            allowFullScreen=""
            loading="lazy"
            css={tw`absolute top-0 left-0 w-full h-full`}
          ></iframe>
        }
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "no-wrap",
          flexDirection: "row",
        }}
        css={imageContainerCss}
        className="text-white"
      >
        <img
          style={{ height: "30vw", width: "30vw" }}
          css={imageCssRounded}
          src={MarkvilleEntrance}
          alt="Markville Outside"
        />
        <img
          style={{ height: "30vw", width: "40vw" }}
          css={imageCss2}
          src={MarkvilleInside}
          alt="Markville Inside"
        />
      </div>
      <MainFeature2
        subheading={<Subheading>{homeText.reputedStore}</Subheading>}
        heading={
          <>
            {homeText.contactUs1}{" "}
            <HighlightedText>{homeText.contactUs2}</HighlightedText>
          </>
        }
        description={[""]}
        statistics={[
          {
            value: homeText.phoneNumber,
            key: "905-886-1688",
          },
          {
            value: homeText.email,
            key: "info@comeforhealth.ca",
          },
        ]}
        imageInsideDiv={true}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={false}
        disableButton={true}
      /> 
      <ProductList unparsedTabs={{ All: data }} />
      <Footer />
    </AnimationRevealPage>
  );
};
