import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { useParams } from "react-router-dom";
import ProductDisplay from "components/features/ProductDisplay";
import Header from "components/headers/light.js";
import { getProductByHandle, getProductByHandleWithTranslations } from "services/productService";
import { LanguageContext } from "context/LanguageContextProvider";

export default ({ isAdmin }) => {
  const { handle } = useParams();
  const [originalProduct, setOriginalProduct] = useState();
  const [state, dispatch] = useContext(LanguageContext);
  const languageCode = state.language.code;

  useEffect(() => {
    getProductByHandleWithTranslations(handle).then((product) => {
      if (languageCode === "cn" && product?.translations?.["chinese [traditional]"]) {
        product.title = product?.translations?.["chinese [traditional]"]?.title ?? "";
        product.body = product?.translations?.["chinese [traditional]"]?.body ?? "";
        product.suggesteduse = product?.translations?.["chinese [traditional]"]?.suggesteduse ?? "";
        product.ingredients = product?.translations?.["chinese [traditional]"]?.ingredients ?? "";
      }
      setOriginalProduct(product);
    });
    // getProductByHandle(handle).then((product) => {
    //   setOriginalProduct(product);
    // });
  }, [languageCode]);

  // function getProductAtIndex(pagedProducts, index) {
  //   const currentPerPage = 8;
  //   return pagedProducts[Math.floor(index / currentPerPage)][
  //     index % currentPerPage
  //   ];
  // }

  return (
    <AnimationRevealPage>
      <Header />
      {originalProduct ? (
        <ProductDisplay isAdmin={isAdmin} originalProduct={originalProduct} languageCode={languageCode} />
      ) : null}
      {originalProduct && originalProduct.Category ? (
        <div>'Products related:' </div>
      ) : (
        ""
      )}
      <Footer />
    </AnimationRevealPage>
  );
};
